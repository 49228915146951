/* global window: false */
/* global alert: false */
/* global FormData: false */
/* global yaCounter46060599: false */
/* global FileReader: false */
/* global screen: false */
/* eslint-env jquery */
/* eslint-disable no-alert */
/* eslint-disable max-len */
/* eslint-disable require-jsdoc */
/* eslint-disable no-unused-vars */
/* eslint max-len: [1, 100] */
// $ = jQuery.noConflict();
var app = {};

app.ajax = null;

app.ui = {
	disableDragging: function() {
		jQuery('img, a').on('dragstart', function(event) {
			event.preventDefault();
		});
	},
	changeViewport: function() {
		var viewport = $('meta[name="viewport"]');

		changeViewport();

		$(window).on('resize', function() {
			changeViewport();
		});

		$(window).on('orientationchange', function() {
			changeViewport();
		});

		function changeViewport() {
			if (screen.availWidth < 640) {
				viewport.attr('content', 'width=640, user-scalable=no');
			} else if ((screen.availWidth < 1024) && (screen.availWidth >= 641)) {
				viewport.attr('content', 'width=1024');
			} else {
				viewport.attr('content', 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no');
			}
		}
	},
	initGallery: function() {
		$('#js-gallery').slick({
			dots: false,
			arrows: true,
			infinite: true,
			speed: 300,
			slidesToShow: 1,
			slidesToScroll: 1,
			centerMode: true,
			variableWidth: true
		});
	},
	initReviews: function() {
		$('#js-reviews').slick({
			dots: false,
			arrows: true,
			infinite: true,
			speed: 300,
			slidesToShow: 1,
			slidesToScroll: 1
		});
	},
	validatePhone: function() {
		$('.js-phone-validator').each(function() {
			$(this).mask('+7 (999) 999-99-99');
		});
	},
	chooseEngyneType: function() {
		$('#js-engine-type-gasoline').on('click', function() {
			$('#js-engine-type-diesel').removeClass('c-services__engine-item--active');
			$(this).addClass('c-services__engine-item--active');
			$('#js-pricing-diesel').addClass('u-hidden');
			$('#js-pricing-gasoline').removeClass('u-hidden');
		});
		$('#js-engine-type-diesel').on('click', function() {
			$('#js-engine-type-gasoline').removeClass('c-services__engine-item--active');
			$(this).addClass('c-services__engine-item--active');
			$('#js-pricing-gasoline').addClass('u-hidden');
			$('#js-pricing-diesel').removeClass('u-hidden');
		});
	},
	addReview: function() {
		$('#js-add-review').on('click', function() {
			$(this).addClass('u-hidden');
			$('#js-reviews').addClass('u-opacity-hidden');
			$('.c-reviews__add').removeClass('u-hidden');
			$('#js-add-review-submit').removeClass('u-hidden');
			return false;
		});
		$('.js-reviews__close').on('click', function() {
			$('#js-add-review').removeClass('u-hidden');
			$('#js-reviews').removeClass('u-opacity-hidden');
			$('.c-reviews__add').addClass('u-hidden');
			$('#js-add-review-submit').addClass('u-hidden');
			return false;
		});
	},
	validateForms: function() {
		$('#js-validate-form').validate({
			errorPlacement: function() {
				return true;
			},
			submitHandler: function() {
				// form.submit();
			}
		});
		$('#js-validate-form-modal').validate({
			errorPlacement: function() {
				return true;
			},
			submitHandler: function() {
				// form.submit();
			}
		});
		$('#js-validate-form-modal-map').validate({
			errorPlacement: function() {
				return true;
			},
			submitHandler: function() {
				// form.submit();
			}
		});
		$('#js-validate-reviews').validate({
			errorPlacement: function() {
				return true;
			},
			showErrors: function() {
				this.defaultShowErrors();
			},
			highlight: function(element) {
				if ($(element).attr('name') === 'reviewsAvatar') {
					$('#reviewsAvatarLabel').addClass('error');
				}
				$(element).addClass('error').removeClass('valid');
			},
			unhighlight: function(element) {
				if ($(element).attr('name') === 'reviewsAvatar') {
					$('#reviewsAvatarLabel').removeClass('error');
				}
				$(element).removeClass('error').addClass('valid');
			},
			rules: {
				reviewsAvatar: {
					required: true,
					extension: 'jpg|jpeg|png|gif'
				}
			},
			submitHandler: function() {
				// form.submit();
			}
		});
		function readURL(input) {
			if (input.files && input.files[0]) {
				var reader = new FileReader();
				reader.onload = function(e) {
					$('#reviewsImg').attr('src', e.target.result);
				};
				reader.readAsDataURL(input.files[0]);
			}
		}

		$('#reviewsAvatar').change(function() {
			readURL(this);
		});
	},
	headerSticky: function() {
		if ($(window).outerWidth() < 1024) {
			var header = '.c-nav';
			var	$header = $(header);
			var clone = $header.before($header.clone().addClass('c-nav--fixed'));

			$(window).scroll(function() {
				var fromTop = $(window).scrollTop();
				$('body').toggleClass('is-scrolled', (fromTop > 100));
			});
		}
	},
	submitForm: function() {
		$('.js-form').submit(function(e) {
			if ($(this).valid()) {
				e.preventDefault();
				var th = $(this);
				$.ajax({
					type: 'POST',
					url: '/send_message.php',
					data: th.serialize()
				}).done(function() {
					alert('Спасибо за Вашу заявку!');
					yaCounter46060599.reachGoal('form');
					setTimeout(function() {
						th.trigger('reset');
					}, 1000);
				});
				return false;
			}
		});
	},
	submitFormModal: function() {
		$('.js-form-modal').submit(function(e) {
			if ($(this).valid()) {
				e.preventDefault();
				var th = $(this);
				$.ajax({
					type: 'POST',
					url: '/send_message.php',
					data: th.serialize()
				}).done(function() {
					alert('Спасибо за Вашу заявку!');
					yaCounter46060599.reachGoal('form');
					setTimeout(function() {
						th.trigger('reset');
					}, 1000);
				});
				return false;
			}
		});
	},
	submitFormModalMap: function() {
		$('.js-form-modal-map').submit(function(e) {
			if ($(this).valid()) {
				e.preventDefault();
				var th = $(this);
				$.ajax({
					type: 'POST',
					url: '/send_message.php',
					data: th.serialize()
				}).done(function() {
					alert('Спасибо за Вашу заявку!');
					yaCounter46060599.reachGoal('form');
					setTimeout(function() {
						th.trigger('reset');
					}, 1000);
				});
				return false;
			}
		});
	},
	submitReview: function() {
		$('.js-form-review').submit(function() {
			if ($(this).valid()) {
				var filedata = $('#reviewsAvatar').prop('files')[0];
				var formdata = new FormData();
				formdata.append('file', filedata);
				$.ajax({
					url: '/send_review.php',
					dataType: 'text',
					cache: false,
					contentType: false,
					processData: false,
					data: formdata,
					type: 'post',
					success: function() {
						alert('Спасибо за Ваш отзыв!');
						$('.js-form-review').trigger('reset');
					}
				});
				return false;
			}
		});
	},
	scrollToAnchor: function() {
		$('.js-scroll-to').each(function() {
			$(this).click(function() {
				var fromTop = 0;
				jQuery('html, body').animate({
					scrollTop: jQuery(jQuery(this).attr('href')).offset().top - fromTop + 'px'
				}, {
					duration: 750,
					easing: 'swing'
				});
				return false;
			});
		});
	},
	showPopup: function() {
		$('.js-open-modal').each(function() {
			$(this).on('click', function() {
				var serviceTitle = $(this).find('.c-services__pricing-title').html();
				$('.js-modal').addClass('is-opened');
				$('#js-modal-overlay').addClass('is-opened');
				$('.js-name-of-service').html(serviceTitle);
			});
		});
		$('.js-open-modal--map').each(function() {
			$(this).on('click', function() {
				$('.js-modal-map').addClass('is-opened');
				$('#js-modal-overlay').addClass('is-opened');
			});
		});
		$('#js-modal-overlay, .js-modal__close').on('click', function() {
			$('.js-modal, .js-modal-map').removeClass('is-opened');
			$('#js-modal-overlay').removeClass('is-opened');
			$('.js-name-of-service').html('');
		});
	},
	utmChangeContent: function() {
		if (window.location.href.indexOf('utm_content') > -1) {
			var url = window.location.href;
			var urlIndex = window.location.href.indexOf('utm_content');
			var urlContains = url.substr(urlIndex + 12);
			if (urlContains.indexOf('&') > -1) {
				urlContains = urlContains.substring(0, urlContains.indexOf('&'));
			} else if (urlContains.indexOf('#') > -1) {
				urlContains = urlContains.substring(0, urlContains.indexOf('#'));
			}

			switch (urlContains) {
			case 'audi':
				// Set theme red
				$('#themeCSS').attr('href', '/styles/theme-red.css');
				// Replace logo
				$('.c-nav__logo').attr('src', '/images/logo-red.png');
				// Set header text
				$('.c-nav__logo-text').append(' AUDI');
				// Replace banner
				$('.c-hero').attr('style', 'background-image: url(\'../uploads/banner-audi.jpg\')');
				// Set hero title
				$('.c-hero__title').html('АВТОСЕРВИС <span>AUDI</span> НА ВАРШАВКЕ <span>33</span>');
				// Set hero description
				$('#js-hero-description').append(' AUDI');
				// Replace man
				$('.c-diagnostics__man').attr('src', '/images/man-red.png');
				// Set engine description
				$('.c-services__description').append(' AUDI');
				// Replace man 2
				$('.c-contacts-info__man').attr('src', '/images/man-2-red.png');
				// Replace footer logo
				$('.c-footer__logo').attr('src', '/images/logo-footer-red.png');
				// Replace contacts text
				$('.js-contacts-replace').html('ПО РЕМОНТУ AUDI');
				// Choose engine
				$('#js-engine-type-gasoline').addClass('c-services__engine-item--active');
				$('#js-engine-type-diesel').removeClass('c-services__engine-item--active');
				$('#js-pricing-gasoline').removeClass('u-hidden');
				$('#js-pricing-diesel').addClass('u-hidden');
				// Replace modal text
				$('.js-name-of-auto').html('AUDI ');
				break;
			case 'audi_diesel':
				// Set theme red
				$('#themeCSS').attr('href', '/styles/theme-red.css');
				// Replace logo
				$('.c-nav__logo').attr('src', '/images/logo-red.png');
				// Set header text
				$('.c-nav__logo-text').append(' AUDI');
				// Replace banner
				$('.c-hero').attr('style', 'background-image: url(\'../uploads/banner-audi.jpg\')');
				// Set hero title
				$('.c-hero__title').html('АВТОСЕРВИС <span>AUDI</span> НА ВАРШАВКЕ <span>33</span>');
				// Set hero description
				$('#js-hero-description').append(' AUDI');
				// Replace man
				$('.c-diagnostics__man').attr('src', '/images/man-red.png');
				// Set engine description
				$('.c-services__description').append(' AUDI');
				// Replace man 2
				$('.c-contacts-info__man').attr('src', '/images/man-2-red.png');
				// Replace footer logo
				$('.c-footer__logo').attr('src', '/images/logo-footer-red.png');
				// Replace contacts text
				$('.js-contacts-replace').html('ПО РЕМОНТУ AUDI');
				// Choose engine
				$('#js-engine-type-gasoline').removeClass('c-services__engine-item--active');
				$('#js-engine-type-diesel').addClass('c-services__engine-item--active');
				$('#js-pricing-gasoline').addClass('u-hidden');
				$('#js-pricing-diesel').removeClass('u-hidden');
				// Replace modal text
				$('.js-name-of-auto').html('AUDI ');
				break;
			case 'bmw':
				// Set theme blue
				$('#themeCSS').attr('href', '/styles/theme-blue.css');
				// Replace logo
				$('.c-nav__logo').attr('src', '/images/logo-blue.png');
				// Set header text
				$('.c-nav__logo-text').append(' BMW');
				// Replace banner
				$('.c-hero').attr('style', 'background-image: url(\'../uploads/banner-bmw.jpg\')');
				// Set hero title
				$('.c-hero__title').html('АВТОСЕРВИС <span>BMW</span> НА ВАРШАВКЕ <span>33</span>');
				// Set hero description
				$('#js-hero-description').append(' BMW');
				// Replace man
				$('.c-diagnostics__man').attr('src', '/images/man-blue.png');
				// Set engine description
				$('.c-services__description').append(' BMW');
				// Replace man 2
				$('.c-contacts-info__man').attr('src', '/images/man-2-blue.png');
				// Replace footer logo
				$('.c-footer__logo').attr('src', '/images/logo-footer-blue.png');
				// Replace contacts text
				$('.js-contacts-replace').html('ПО РЕМОНТУ BMW');
				// Choose engine
				$('#js-engine-type-gasoline').addClass('c-services__engine-item--active');
				$('#js-engine-type-diesel').removeClass('c-services__engine-item--active');
				$('#js-pricing-gasoline').removeClass('u-hidden');
				$('#js-pricing-diesel').addClass('u-hidden');
				// Replace modal text
				$('.js-name-of-auto').html('BMW ');
				break;
			case 'bmw_diesel':
				// Set theme blue
				$('#themeCSS').attr('href', '/styles/theme-blue.css');
				// Replace logo
				$('.c-nav__logo').attr('src', '/images/logo-blue.png');
				// Set header text
				$('.c-nav__logo-text').append(' BMW');
				// Replace banner
				$('.c-hero').attr('style', 'background-image: url(\'../uploads/banner-bmw.jpg\')');
				// Set hero title
				$('.c-hero__title').html('АВТОСЕРВИС <span>BMW</span> НА ВАРШАВКЕ <span>33</span>');
				// Set hero description
				$('#js-hero-description').append(' BMW');
				// Replace man
				$('.c-diagnostics__man').attr('src', '/images/man-blue.png');
				// Set engine description
				$('.c-services__description').append(' BMW');
				// Replace man 2
				$('.c-contacts-info__man').attr('src', '/images/man-2-blue.png');
				// Replace footer logo
				$('.c-footer__logo').attr('src', '/images/logo-footer-blue.png');
				// Replace contacts text
				$('.js-contacts-replace').html('ПО РЕМОНТУ BMW');
				// Choose engine
				$('#js-engine-type-gasoline').removeClass('c-services__engine-item--active');
				$('#js-engine-type-diesel').addClass('c-services__engine-item--active');
				$('#js-pricing-gasoline').addClass('u-hidden');
				$('#js-pricing-diesel').removeClass('u-hidden');
				// Replace modal text
				$('.js-name-of-auto').html('BMW ');
				break;
			case 'volvo':
				// Set theme blue
				$('#themeCSS').attr('href', '/styles/theme-blue.css');
				// Replace logo
				$('.c-nav__logo').attr('src', '/images/logo-blue.png');
				// Set header text
				$('.c-nav__logo-text').append(' VOLVO');
				// Replace banner
				$('.c-hero').attr('style', 'background-image: url(\'../uploads/banner-volvo.jpg\')');
				// Set hero title
				$('.c-hero__title').html('АВТОСЕРВИС <span>VOLVO</span> НА ВАРШАВКЕ <span>33</span>');
				// Set hero description
				$('#js-hero-description').append(' VOLVO');
				// Replace man
				$('.c-diagnostics__man').attr('src', '/images/man-blue.png');
				// Set engine description
				$('.c-services__description').append(' VOLVO');
				// Replace man 2
				$('.c-contacts-info__man').attr('src', '/images/man-2-blue.png');
				// Replace footer logo
				$('.c-footer__logo').attr('src', '/images/logo-footer-blue.png');
				// Replace contacts text
				$('.js-contacts-replace').html('ПО РЕМОНТУ VOLVO');
				// Choose engine
				$('#js-engine-type-gasoline').addClass('c-services__engine-item--active');
				$('#js-engine-type-diesel').removeClass('c-services__engine-item--active');
				$('#js-pricing-gasoline').removeClass('u-hidden');
				$('#js-pricing-diesel').addClass('u-hidden');
				// Replace modal text
				$('.js-name-of-auto').html('VOLVO ');
				break;
			case 'volvo_diesel':
				// Set theme blue
				$('#themeCSS').attr('href', '/styles/theme-blue.css');
				// Replace logo
				$('.c-nav__logo').attr('src', '/images/logo-blue.png');
				// Set header text
				$('.c-nav__logo-text').append(' VOLVO');
				// Replace banner
				$('.c-hero').attr('style', 'background-image: url(\'../uploads/banner-volvo.jpg\')');
				// Set hero title
				$('.c-hero__title').html('АВТОСЕРВИС <span>VOLVO</span> НА ВАРШАВКЕ <span>33</span>');
				// Set hero description
				$('#js-hero-description').append(' VOLVO');
				// Replace man
				$('.c-diagnostics__man').attr('src', '/images/man-blue.png');
				// Set engine description
				$('.c-services__description').append(' VOLVO');
				// Replace man 2
				$('.c-contacts-info__man').attr('src', '/images/man-2-blue.png');
				// Replace footer logo
				$('.c-footer__logo').attr('src', '/images/logo-footer-blue.png');
				// Replace contacts text
				$('.js-contacts-replace').html('ПО РЕМОНТУ VOLVO');
				// Choose engine
				$('#js-engine-type-gasoline').removeClass('c-services__engine-item--active');
				$('#js-engine-type-diesel').addClass('c-services__engine-item--active');
				$('#js-pricing-gasoline').addClass('u-hidden');
				$('#js-pricing-diesel').removeClass('u-hidden');
				// Replace modal text
				$('.js-name-of-auto').html('VOLVO ');
				break;
			case 'kia':
				// Set theme red
				$('#themeCSS').attr('href', '/styles/theme-red.css');
				// Replace logo
				$('.c-nav__logo').attr('src', '/images/logo-red.png');
				// Set header text
				$('.c-nav__logo-text').append(' KIA');
				// Replace banner
				$('.c-hero').attr('style', 'background-image: url(\'../uploads/banner-kia.jpg\')');
				// Set hero title
				$('.c-hero__title').html('АВТОСЕРВИС <span>KIA</span> НА ВАРШАВКЕ <span>33</span>');
				// Set hero description
				$('#js-hero-description').append(' KIA');
				// Replace man
				$('.c-diagnostics__man').attr('src', '/images/man-red.png');
				// Set engine description
				$('.c-services__description').append(' KIA');
				// Replace man 2
				$('.c-contacts-info__man').attr('src', '/images/man-2-red.png');
				// Replace footer logo
				$('.c-footer__logo').attr('src', '/images/logo-footer-red.png');
				// Replace contacts text
				$('.js-contacts-replace').html('ПО РЕМОНТУ KIA');
				// Choose engine
				$('#js-engine-type-gasoline').addClass('c-services__engine-item--active');
				$('#js-engine-type-diesel').removeClass('c-services__engine-item--active');
				$('#js-pricing-gasoline').removeClass('u-hidden');
				$('#js-pricing-diesel').addClass('u-hidden');
				// Replace modal text
				$('.js-name-of-auto').html('KIA ');
				break;
			case 'kia_diesel':
				// Set theme red
				$('#themeCSS').attr('href', '/styles/theme-red.css');
				// Replace logo
				$('.c-nav__logo').attr('src', '/images/logo-red.png');
				// Set header text
				$('.c-nav__logo-text').append(' KIA');
				// Replace banner
				$('.c-hero').attr('style', 'background-image: url(\'../uploads/banner-kia.jpg\')');
				// Set hero title
				$('.c-hero__title').html('АВТОСЕРВИС <span>KIA</span> НА ВАРШАВКЕ <span>33</span>');
				// Set hero description
				$('#js-hero-description').append(' KIA');
				// Replace man
				$('.c-diagnostics__man').attr('src', '/images/man-red.png');
				// Set engine description
				$('.c-services__description').append(' KIA');
				// Replace man 2
				$('.c-contacts-info__man').attr('src', '/images/man-2-red.png');
				// Replace footer logo
				$('.c-footer__logo').attr('src', '/images/logo-footer-red.png');
				// Replace contacts text
				$('.js-contacts-replace').html('ПО РЕМОНТУ KIA');
				// Choose engine
				$('#js-engine-type-gasoline').removeClass('c-services__engine-item--active');
				$('#js-engine-type-diesel').addClass('c-services__engine-item--active');
				$('#js-pricing-gasoline').addClass('u-hidden');
				$('#js-pricing-diesel').removeClass('u-hidden');
				// Replace modal text
				$('.js-name-of-auto').html('KIA ');
				break;
			case 'mazda':
				// Set theme red
				$('#themeCSS').attr('href', '/styles/theme-red.css');
				// Replace logo
				$('.c-nav__logo').attr('src', '/images/logo-red.png');
				// Set header text
				$('.c-nav__logo-text').append(' MAZDA');
				// Replace banner
				$('.c-hero').attr('style', 'background-image: url(\'../uploads/banner-mazda.jpg\')');
				// Set hero title
				$('.c-hero__title').html('АВТОСЕРВИС <span>MAZDA</span> НА ВАРШАВКЕ <span>33</span>');
				// Set hero description
				$('#js-hero-description').append(' MAZDA');
				// Replace man
				$('.c-diagnostics__man').attr('src', '/images/man-red.png');
				// Set engine description
				$('.c-services__description').append(' MAZDA');
				// Replace man 2
				$('.c-contacts-info__man').attr('src', '/images/man-2-red.png');
				// Replace footer logo
				$('.c-footer__logo').attr('src', '/images/logo-footer-red.png');
				// Replace contacts text
				$('.js-contacts-replace').html('ПО РЕМОНТУ MAZDA');
				// Choose engine
				$('#js-engine-type-gasoline').addClass('c-services__engine-item--active');
				$('#js-engine-type-diesel').removeClass('c-services__engine-item--active');
				$('#js-pricing-gasoline').removeClass('u-hidden');
				$('#js-pricing-diesel').addClass('u-hidden');
				// Replace modal text
				$('.js-name-of-auto').html('MAZDA ');
				break;
			case 'mazda_diesel':
				// Set theme red
				$('#themeCSS').attr('href', '/styles/theme-red.css');
				// Replace logo
				$('.c-nav__logo').attr('src', '/images/logo-red.png');
				// Set header text
				$('.c-nav__logo-text').append(' MAZDA');
				// Replace banner
				$('.c-hero').attr('style', 'background-image: url(\'../uploads/banner-mazda.jpg\')');
				// Set hero title
				$('.c-hero__title').html('АВТОСЕРВИС <span>MAZDA</span> НА ВАРШАВКЕ <span>33</span>');
				// Set hero description
				$('#js-hero-description').append(' MAZDA');
				// Replace man
				$('.c-diagnostics__man').attr('src', '/images/man-red.png');
				// Set engine description
				$('.c-services__description').append(' MAZDA');
				// Replace man 2
				$('.c-contacts-info__man').attr('src', '/images/man-2-red.png');
				// Replace footer logo
				$('.c-footer__logo').attr('src', '/images/logo-footer-red.png');
				// Replace contacts text
				$('.js-contacts-replace').html('ПО РЕМОНТУ MAZDA');
				// Choose engine
				$('#js-engine-type-gasoline').removeClass('c-services__engine-item--active');
				$('#js-engine-type-diesel').addClass('c-services__engine-item--active');
				$('#js-pricing-gasoline').addClass('u-hidden');
				$('#js-pricing-diesel').removeClass('u-hidden');
				// Replace modal text
				$('.js-name-of-auto').html('MAZDA ');
				break;
			case 'mercedes':
				// Set theme blue
				$('#themeCSS').attr('href', '/styles/theme-blue.css');
				// Replace logo
				$('.c-nav__logo').attr('src', '/images/logo-blue.png');
				// Set header text
				$('.c-nav__logo-text').append(' MERCEDES');
				// Replace banner
				$('.c-hero').attr('style', 'background-image: url(\'../uploads/banner-mercedes.jpg\')');
				// Set hero title
				$('.c-hero__title').html('АВТОСЕРВИС <span>MERCEDES</span> НА ВАРШАВКЕ <span>33</span>');
				// Set hero description
				$('#js-hero-description').append(' MERCEDES');
				// Replace man
				$('.c-diagnostics__man').attr('src', '/images/man-blue.png');
				// Set engine description
				$('.c-services__description').append(' MERCEDES');
				// Replace man 2
				$('.c-contacts-info__man').attr('src', '/images/man-2-blue.png');
				// Replace footer logo
				$('.c-footer__logo').attr('src', '/images/logo-footer-blue.png');
				// Replace contacts text
				$('.js-contacts-replace').html('ПО РЕМОНТУ MERCEDES');
				// Choose engine
				$('#js-engine-type-gasoline').addClass('c-services__engine-item--active');
				$('#js-engine-type-diesel').removeClass('c-services__engine-item--active');
				$('#js-pricing-gasoline').removeClass('u-hidden');
				$('#js-pricing-diesel').addClass('u-hidden');
				// Replace modal text
				$('.js-name-of-auto').html('MERCEDES ');
				break;
			case 'mercedes_diesel':
				// Set theme blue
				$('#themeCSS').attr('href', '/styles/theme-blue.css');
				// Replace logo
				$('.c-nav__logo').attr('src', '/images/logo-blue.png');
				// Set header text
				$('.c-nav__logo-text').append(' MERCEDES');
				// Replace banner
				$('.c-hero').attr('style', 'background-image: url(\'../uploads/banner-mercedes.jpg\')');
				// Set hero title
				$('.c-hero__title').html('АВТОСЕРВИС <span>MERCEDES</span> НА ВАРШАВКЕ <span>33</span>');
				// Set hero description
				$('#js-hero-description').append(' MERCEDES');
				// Replace man
				$('.c-diagnostics__man').attr('src', '/images/man-blue.png');
				// Set engine description
				$('.c-services__description').append(' MERCEDES');
				// Replace man 2
				$('.c-contacts-info__man').attr('src', '/images/man-2-blue.png');
				// Replace footer logo
				$('.c-footer__logo').attr('src', '/images/logo-footer-blue.png');
				// Replace contacts text
				$('.js-contacts-replace').html('ПО РЕМОНТУ MERCEDES');
				// Choose engine
				$('#js-engine-type-gasoline').removeClass('c-services__engine-item--active');
				$('#js-engine-type-diesel').addClass('c-services__engine-item--active');
				$('#js-pricing-gasoline').addClass('u-hidden');
				$('#js-pricing-diesel').removeClass('u-hidden');
				// Replace modal text
				$('.js-name-of-auto').html('MERCEDES ');
				break;
			case 'mitsubishi':
				// Set theme red
				$('#themeCSS').attr('href', '/styles/theme-red.css');
				// Replace logo
				$('.c-nav__logo').attr('src', '/images/logo-red.png');
				// Set header text
				$('.c-nav__logo-text').append(' MITSUBISHI');
				// Replace banner
				$('.c-hero').attr('style', 'background-image: url(\'../uploads/banner-mitsubishi.jpg\')');
				// Set hero title
				$('.c-hero__title').html('АВТОСЕРВИС <span>MITSUBISHI</span> НА ВАРШАВКЕ <span>33</span>');
				// Set hero description
				$('#js-hero-description').append(' MITSUBISHI');
				// Replace man
				$('.c-diagnostics__man').attr('src', '/images/man-red.png');
				// Set engine description
				$('.c-services__description').append(' MITSUBISHI');
				// Replace man 2
				$('.c-contacts-info__man').attr('src', '/images/man-2-red.png');
				// Replace footer logo
				$('.c-footer__logo').attr('src', '/images/logo-footer-red.png');
				// Replace contacts text
				$('.js-contacts-replace').html('ПО РЕМОНТУ MITSUBISHI');
				// Choose engine
				$('#js-engine-type-gasoline').addClass('c-services__engine-item--active');
				$('#js-engine-type-diesel').removeClass('c-services__engine-item--active');
				$('#js-pricing-gasoline').removeClass('u-hidden');
				$('#js-pricing-diesel').addClass('u-hidden');
				// Replace modal text
				$('.js-name-of-auto').html('MITSUBISHI ');
				break;
			case 'mitsubishi_diesel':
				// Set theme red
				$('#themeCSS').attr('href', '/styles/theme-red.css');
				// Replace logo
				$('.c-nav__logo').attr('src', '/images/logo-red.png');
				// Set header text
				$('.c-nav__logo-text').append(' MITSUBISHI');
				// Replace banner
				$('.c-hero').attr('style', 'background-image: url(\'../uploads/banner-mitsubishi.jpg\')');
				// Set hero title
				$('.c-hero__title').html('АВТОСЕРВИС <span>MITSUBISHI</span> НА ВАРШАВКЕ <span>33</span>');
				// Set hero description
				$('#js-hero-description').append(' MITSUBISHI');
				// Replace man
				$('.c-diagnostics__man').attr('src', '/images/man-red.png');
				// Set engine description
				$('.c-services__description').append(' MITSUBISHI');
				// Replace man 2
				$('.c-contacts-info__man').attr('src', '/images/man-2-red.png');
				// Replace footer logo
				$('.c-footer__logo').attr('src', '/images/logo-footer-red.png');
				// Replace contacts text
				$('.js-contacts-replace').html('ПО РЕМОНТУ MITSUBISHI');
				// Choose engine
				$('#js-engine-type-gasoline').removeClass('c-services__engine-item--active');
				$('#js-engine-type-diesel').addClass('c-services__engine-item--active');
				$('#js-pricing-gasoline').addClass('u-hidden');
				$('#js-pricing-diesel').removeClass('u-hidden');
				// Replace modal text
				$('.js-name-of-auto').html('MITSUBISHI ');
				break;
			case 'citroen':
				// Set theme red
				$('#themeCSS').attr('href', '/styles/theme-red.css');
				// Replace logo
				$('.c-nav__logo').attr('src', '/images/logo-red.png');
				// Set header text
				$('.c-nav__logo-text').append(' CITROËN');
				// Replace banner
				$('.c-hero').attr('style', 'background-image: url(\'../uploads/banner-citroen.jpg\')');
				// Set hero title
				$('.c-hero__title').html('АВТОСЕРВИС <span>CITROËN</span> НА ВАРШАВКЕ <span>33</span>');
				// Set hero description
				$('#js-hero-description').append(' CITROËN');
				// Replace man
				$('.c-diagnostics__man').attr('src', '/images/man-red.png');
				// Set engine description
				$('.c-services__description').append(' CITROËN');
				// Replace man 2
				$('.c-contacts-info__man').attr('src', '/images/man-2-red.png');
				// Replace footer logo
				$('.c-footer__logo').attr('src', '/images/logo-footer-red.png');
				// Replace contacts text
				$('.js-contacts-replace').html('ПО РЕМОНТУ CITROËN');
				// Choose engine
				$('#js-engine-type-gasoline').addClass('c-services__engine-item--active');
				$('#js-engine-type-diesel').removeClass('c-services__engine-item--active');
				$('#js-pricing-gasoline').removeClass('u-hidden');
				$('#js-pricing-diesel').addClass('u-hidden');
				// Replace modal text
				$('.js-name-of-auto').html('CITROËN ');
				break;
			case 'citroen_diesel':
				// Set theme red
				$('#themeCSS').attr('href', '/styles/theme-red.css');
				// Replace logo
				$('.c-nav__logo').attr('src', '/images/logo-red.png');
				// Set header text
				$('.c-nav__logo-text').append(' CITROËN');
				// Replace banner
				$('.c-hero').attr('style', 'background-image: url(\'../uploads/banner-citroen.jpg\')');
				// Set hero title
				$('.c-hero__title').html('АВТОСЕРВИС <span>CITROËN</span> НА ВАРШАВКЕ <span>33</span>');
				// Set hero description
				$('#js-hero-description').append(' CITROËN');
				// Replace man
				$('.c-diagnostics__man').attr('src', '/images/man-red.png');
				// Set engine description
				$('.c-services__description').append(' CITROËN');
				// Replace man 2
				$('.c-contacts-info__man').attr('src', '/images/man-2-red.png');
				// Replace footer logo
				$('.c-footer__logo').attr('src', '/images/logo-footer-red.png');
				// Replace contacts text
				$('.js-contacts-replace').html('ПО РЕМОНТУ CITROËN');
				// Choose engine
				$('#js-engine-type-gasoline').removeClass('c-services__engine-item--active');
				$('#js-engine-type-diesel').addClass('c-services__engine-item--active');
				$('#js-pricing-gasoline').addClass('u-hidden');
				$('#js-pricing-diesel').removeClass('u-hidden');
				// Replace modal text
				$('.js-name-of-auto').html('CITROËN ');
				break;
			case 'toyota':
				// Set theme red
				$('#themeCSS').attr('href', '/styles/theme-red.css');
				// Replace logo
				$('.c-nav__logo').attr('src', '/images/logo-red.png');
				// Set header text
				$('.c-nav__logo-text').append(' TOYOTA');
				// Replace banner
				$('.c-hero').attr('style', 'background-image: url(\'../uploads/banner-toyota.jpg\')');
				// Set hero title
				$('.c-hero__title').html('АВТОСЕРВИС <span>TOYOTA</span> НА ВАРШАВКЕ <span>33</span>');
				// Set hero description
				$('#js-hero-description').append(' TOYOTA');
				// Replace man
				$('.c-diagnostics__man').attr('src', '/images/man-red.png');
				// Set engine description
				$('.c-services__description').append(' TOYOTA');
				// Replace man 2
				$('.c-contacts-info__man').attr('src', '/images/man-2-red.png');
				// Replace footer logo
				$('.c-footer__logo').attr('src', '/images/logo-footer-red.png');
				// Replace contacts text
				$('.js-contacts-replace').html('ПО РЕМОНТУ TOYOTA');
				// Choose engine
				$('#js-engine-type-gasoline').addClass('c-services__engine-item--active');
				$('#js-engine-type-diesel').removeClass('c-services__engine-item--active');
				$('#js-pricing-gasoline').removeClass('u-hidden');
				$('#js-pricing-diesel').addClass('u-hidden');
				// Replace modal text
				$('.js-name-of-auto').html('TOYOTA ');
				break;
			case 'toyota_diesel':
				// Set theme red
				$('#themeCSS').attr('href', '/styles/theme-red.css');
				// Replace logo
				$('.c-nav__logo').attr('src', '/images/logo-red.png');
				// Set header text
				$('.c-nav__logo-text').append(' TOYOTA');
				// Replace banner
				$('.c-hero').attr('style', 'background-image: url(\'../uploads/banner-toyota.jpg\')');
				// Set hero title
				$('.c-hero__title').html('АВТОСЕРВИС <span>TOYOTA</span> НА ВАРШАВКЕ <span>33</span>');
				// Set hero description
				$('#js-hero-description').append(' TOYOTA');
				// Replace man
				$('.c-diagnostics__man').attr('src', '/images/man-red.png');
				// Set engine description
				$('.c-services__description').append(' TOYOTA');
				// Replace man 2
				$('.c-contacts-info__man').attr('src', '/images/man-2-red.png');
				// Replace footer logo
				$('.c-footer__logo').attr('src', '/images/logo-footer-red.png');
				// Replace contacts text
				$('.js-contacts-replace').html('ПО РЕМОНТУ TOYOTA');
				// Choose engine
				$('#js-engine-type-gasoline').removeClass('c-services__engine-item--active');
				$('#js-engine-type-diesel').addClass('c-services__engine-item--active');
				$('#js-pricing-gasoline').addClass('u-hidden');
				$('#js-pricing-diesel').removeClass('u-hidden');
				// Replace modal text
				$('.js-name-of-auto').html('TOYOTA ');
				break;
			case 'hyundai':
				// Set theme red
				$('#themeCSS').attr('href', '/styles/theme-red.css');
				// Replace logo
				$('.c-nav__logo').attr('src', '/images/logo-red.png');
				// Set header text
				$('.c-nav__logo-text').append(' HYUNDAI');
				// Replace banner
				$('.c-hero').attr('style', 'background-image: url(\'../uploads/banner-hyundai.jpg\')');
				// Set hero title
				$('.c-hero__title').html('АВТОСЕРВИС <span>HYUNDAI</span> НА ВАРШАВКЕ <span>33</span>');
				// Set hero description
				$('#js-hero-description').append(' HYUNDAI');
				// Replace man
				$('.c-diagnostics__man').attr('src', '/images/man-red.png');
				// Set engine description
				$('.c-services__description').append(' HYUNDAI');
				// Replace man 2
				$('.c-contacts-info__man').attr('src', '/images/man-2-red.png');
				// Replace footer logo
				$('.c-footer__logo').attr('src', '/images/logo-footer-red.png');
				// Replace contacts text
				$('.js-contacts-replace').html('ПО РЕМОНТУ HYUNDAI');
				// Choose engine
				$('#js-engine-type-gasoline').addClass('c-services__engine-item--active');
				$('#js-engine-type-diesel').removeClass('c-services__engine-item--active');
				$('#js-pricing-gasoline').removeClass('u-hidden');
				$('#js-pricing-diesel').addClass('u-hidden');
				// Replace modal text
				$('.js-name-of-auto').html('HYUNDAI ');
				break;
			case 'hyundai_diesel':
				// Set theme red
				$('#themeCSS').attr('href', '/styles/theme-red.css');
				// Replace logo
				$('.c-nav__logo').attr('src', '/images/logo-red.png');
				// Set header text
				$('.c-nav__logo-text').append(' HYUNDAI');
				// Replace banner
				$('.c-hero').attr('style', 'background-image: url(\'../uploads/banner-hyundai.jpg\')');
				// Set hero title
				$('.c-hero__title').html('АВТОСЕРВИС <span>HYUNDAI</span> НА ВАРШАВКЕ <span>33</span>');
				// Set hero description
				$('#js-hero-description').append(' HYUNDAI');
				// Replace man
				$('.c-diagnostics__man').attr('src', '/images/man-red.png');
				// Set engine description
				$('.c-services__description').append(' HYUNDAI');
				// Replace man 2
				$('.c-contacts-info__man').attr('src', '/images/man-2-red.png');
				// Replace footer logo
				$('.c-footer__logo').attr('src', '/images/logo-footer-red.png');
				// Replace contacts text
				$('.js-contacts-replace').html('ПО РЕМОНТУ HYUNDAI');
				// Choose engine
				$('#js-engine-type-gasoline').removeClass('c-services__engine-item--active');
				$('#js-engine-type-diesel').addClass('c-services__engine-item--active');
				$('#js-pricing-gasoline').addClass('u-hidden');
				$('#js-pricing-diesel').removeClass('u-hidden');
				// Replace modal text
				$('.js-name-of-auto').html('HYUNDAI ');
				break;
			case 'honda':
				// Set theme red
				$('#themeCSS').attr('href', '/styles/theme-red.css');
				// Replace logo
				$('.c-nav__logo').attr('src', '/images/logo-red.png');
				// Set header text
				$('.c-nav__logo-text').append(' HONDA');
				// Replace banner
				$('.c-hero').attr('style', 'background-image: url(\'../uploads/banner-honda.jpg\')');
				// Set hero title
				$('.c-hero__title').html('АВТОСЕРВИС <span>HONDA</span> НА ВАРШАВКЕ <span>33</span>');
				// Set hero description
				$('#js-hero-description').append(' HONDA');
				// Replace man
				$('.c-diagnostics__man').attr('src', '/images/man-red.png');
				// Set engine description
				$('.c-services__description').append(' HONDA');
				// Replace man 2
				$('.c-contacts-info__man').attr('src', '/images/man-2-red.png');
				// Replace footer logo
				$('.c-footer__logo').attr('src', '/images/logo-footer-red.png');
				// Replace contacts text
				$('.js-contacts-replace').html('ПО РЕМОНТУ HONDA');
				// Choose engine
				$('#js-engine-type-gasoline').addClass('c-services__engine-item--active');
				$('#js-engine-type-diesel').removeClass('c-services__engine-item--active');
				$('#js-pricing-gasoline').removeClass('u-hidden');
				$('#js-pricing-diesel').addClass('u-hidden');
				// Replace modal text
				$('.js-name-of-auto').html('HONDA ');
				break;
			case 'honda_diesel':
				// Set theme red
				$('#themeCSS').attr('href', '/styles/theme-red.css');
				// Replace logo
				$('.c-nav__logo').attr('src', '/images/logo-red.png');
				// Set header text
				$('.c-nav__logo-text').append(' HONDA');
				// Replace banner
				$('.c-hero').attr('style', 'background-image: url(\'../uploads/banner-honda.jpg\')');
				// Set hero title
				$('.c-hero__title').html('АВТОСЕРВИС <span>HONDA</span> НА ВАРШАВКЕ <span>33</span>');
				// Set hero description
				$('#js-hero-description').append(' HONDA');
				// Replace man
				$('.c-diagnostics__man').attr('src', '/images/man-red.png');
				// Set engine description
				$('.c-services__description').append(' HONDA');
				// Replace man 2
				$('.c-contacts-info__man').attr('src', '/images/man-2-red.png');
				// Replace footer logo
				$('.c-footer__logo').attr('src', '/images/logo-footer-red.png');
				// Replace contacts text
				$('.js-contacts-replace').html('ПО РЕМОНТУ HONDA');
				// Choose engine
				$('#js-engine-type-gasoline').removeClass('c-services__engine-item--active');
				$('#js-engine-type-diesel').addClass('c-services__engine-item--active');
				$('#js-pricing-gasoline').addClass('u-hidden');
				$('#js-pricing-diesel').removeClass('u-hidden');
				// Replace modal text
				$('.js-name-of-auto').html('HONDA ');
				break;
			case 'vw':
				// Set theme blue
				$('#themeCSS').attr('href', '/styles/theme-blue.css');
				// Replace logo
				$('.c-nav__logo').attr('src', '/images/logo-blue.png');
				// Set header text
				$('.c-nav__logo-text').append(' VW');
				// Replace banner
				$('.c-hero').attr('style', 'background-image: url(\'../uploads/banner-vw.jpg\')');
				// Set hero title
				$('.c-hero__title').html('АВТОСЕРВИС <span>VW</span> НА ВАРШАВКЕ <span>33</span>');
				// Set hero description
				$('#js-hero-description').append(' VW');
				// Replace man
				$('.c-diagnostics__man').attr('src', '/images/man-blue.png');
				// Set engine description
				$('.c-services__description').append(' VW');
				// Replace man 2
				$('.c-contacts-info__man').attr('src', '/images/man-2-blue.png');
				// Replace footer logo
				$('.c-footer__logo').attr('src', '/images/logo-footer-blue.png');
				// Replace contacts text
				$('.js-contacts-replace').html('ПО РЕМОНТУ VW');
				// Choose engine
				$('#js-engine-type-gasoline').addClass('c-services__engine-item--active');
				$('#js-engine-type-diesel').removeClass('c-services__engine-item--active');
				$('#js-pricing-gasoline').removeClass('u-hidden');
				$('#js-pricing-diesel').addClass('u-hidden');
				// Replace modal text
				$('.js-name-of-auto').html('VW ');
				break;
			case 'vw_diesel':
				// Set theme blue
				$('#themeCSS').attr('href', '/styles/theme-blue.css');
				// Replace logo
				$('.c-nav__logo').attr('src', '/images/logo-blue.png');
				// Set header text
				$('.c-nav__logo-text').append(' VW');
				// Replace banner
				$('.c-hero').attr('style', 'background-image: url(\'../uploads/banner-vw.jpg\')');
				// Set hero title
				$('.c-hero__title').html('АВТОСЕРВИС <span>VW</span> НА ВАРШАВКЕ <span>33</span>');
				// Set hero description
				$('#js-hero-description').append(' VW');
				// Replace man
				$('.c-diagnostics__man').attr('src', '/images/man-blue.png');
				// Set engine description
				$('.c-services__description').append(' VW');
				// Replace man 2
				$('.c-contacts-info__man').attr('src', '/images/man-2-blue.png');
				// Replace footer logo
				$('.c-footer__logo').attr('src', '/images/logo-footer-blue.png');
				// Replace contacts text
				$('.js-contacts-replace').html('ПО РЕМОНТУ VW');
				// Choose engine
				$('#js-engine-type-gasoline').removeClass('c-services__engine-item--active');
				$('#js-engine-type-diesel').addClass('c-services__engine-item--active');
				$('#js-pricing-gasoline').addClass('u-hidden');
				$('#js-pricing-diesel').removeClass('u-hidden');
				// Replace modal text
				$('.js-name-of-auto').html('VW ');
				break;
			case 'ford':
				// Set theme blue
				$('#themeCSS').attr('href', '/styles/theme-blue.css');
				// Replace logo
				$('.c-nav__logo').attr('src', '/images/logo-blue.png');
				// Set header text
				$('.c-nav__logo-text').append(' FORD');
				// Replace banner
				$('.c-hero').attr('style', 'background-image: url(\'../uploads/banner-ford.jpg\')');
				// Set hero title
				$('.c-hero__title').html('АВТОСЕРВИС <span>FORD</span> НА ВАРШАВКЕ <span>33</span>');
				// Set hero description
				$('#js-hero-description').append(' FORD');
				// Replace man
				$('.c-diagnostics__man').attr('src', '/images/man-blue.png');
				// Set engine description
				$('.c-services__description').append(' FORD');
				// Replace man 2
				$('.c-contacts-info__man').attr('src', '/images/man-2-blue.png');
				// Replace footer logo
				$('.c-footer__logo').attr('src', '/images/logo-footer-blue.png');
				// Replace contacts text
				$('.js-contacts-replace').html('ПО РЕМОНТУ FORD');
				// Choose engine
				$('#js-engine-type-gasoline').addClass('c-services__engine-item--active');
				$('#js-engine-type-diesel').removeClass('c-services__engine-item--active');
				$('#js-pricing-gasoline').removeClass('u-hidden');
				$('#js-pricing-diesel').addClass('u-hidden');
				// Replace modal text
				$('.js-name-of-auto').html('FORD ');
				break;
			case 'ford_diesel':
				// Set theme blue
				$('#themeCSS').attr('href', '/styles/theme-blue.css');
				// Replace logo
				$('.c-nav__logo').attr('src', '/images/logo-blue.png');
				// Set header text
				$('.c-nav__logo-text').append(' FORD');
				// Replace banner
				$('.c-hero').attr('style', 'background-image: url(\'../uploads/banner-ford.jpg\')');
				// Set hero title
				$('.c-hero__title').html('АВТОСЕРВИС <span>FORD</span> НА ВАРШАВКЕ <span>33</span>');
				// Set hero description
				$('#js-hero-description').append(' FORD');
				// Replace man
				$('.c-diagnostics__man').attr('src', '/images/man-blue.png');
				// Set engine description
				$('.c-services__description').append(' FORD');
				// Replace man 2
				$('.c-contacts-info__man').attr('src', '/images/man-2-blue.png');
				// Replace footer logo
				$('.c-footer__logo').attr('src', '/images/logo-footer-blue.png');
				// Replace contacts text
				$('.js-contacts-replace').html('ПО РЕМОНТУ FORD');
				// Choose engine
				$('#js-engine-type-gasoline').removeClass('c-services__engine-item--active');
				$('#js-engine-type-diesel').addClass('c-services__engine-item--active');
				$('#js-pricing-gasoline').addClass('u-hidden');
				$('#js-pricing-diesel').removeClass('u-hidden');
				// Replace modal text
				$('.js-name-of-auto').html('FORD ');
				break;
			case 'chevrolet':
				// Set theme blue
				$('#themeCSS').attr('href', '/styles/theme-blue.css');
				// Replace logo
				$('.c-nav__logo').attr('src', '/images/logo-blue.png');
				// Set header text
				$('.c-nav__logo-text').append(' CHEVROLET');
				// Replace banner
				$('.c-hero').attr('style', 'background-image: url(\'../uploads/banner-chevrolet.jpg\')');
				// Set hero title
				$('.c-hero__title').html('АВТОСЕРВИС <span>CHEVROLET</span> НА ВАРШАВКЕ <span>33</span>');
				// Set hero description
				$('#js-hero-description').append(' CHEVROLET');
				// Replace man
				$('.c-diagnostics__man').attr('src', '/images/man-blue.png');
				// Set engine description
				$('.c-services__description').append(' CHEVROLET');
				// Replace man 2
				$('.c-contacts-info__man').attr('src', '/images/man-2-blue.png');
				// Replace footer logo
				$('.c-footer__logo').attr('src', '/images/logo-footer-blue.png');
				// Replace contacts text
				$('.js-contacts-replace').html('ПО РЕМОНТУ CHEVROLET');
				// Choose engine
				$('#js-engine-type-gasoline').addClass('c-services__engine-item--active');
				$('#js-engine-type-diesel').removeClass('c-services__engine-item--active');
				$('#js-pricing-gasoline').removeClass('u-hidden');
				$('#js-pricing-diesel').addClass('u-hidden');
				// Replace modal text
				$('.js-name-of-auto').html('CHEVROLET ');
				break;
			case 'chevrolet_diesel':
				// Set theme blue
				$('#themeCSS').attr('href', '/styles/theme-blue.css');
				// Replace logo
				$('.c-nav__logo').attr('src', '/images/logo-blue.png');
				// Set header text
				$('.c-nav__logo-text').append(' CHEVROLET');
				// Replace banner
				$('.c-hero').attr('style', 'background-image: url(\'../uploads/banner-chevrolet.jpg\')');
				// Set hero title
				$('.c-hero__title').html('АВТОСЕРВИС <span>CHEVROLET</span> НА ВАРШАВКЕ <span>33</span>');
				// Set hero description
				$('#js-hero-description').append(' CHEVROLET');
				// Replace man
				$('.c-diagnostics__man').attr('src', '/images/man-blue.png');
				// Set engine description
				$('.c-services__description').append(' CHEVROLET');
				// Replace man 2
				$('.c-contacts-info__man').attr('src', '/images/man-2-blue.png');
				// Replace footer logo
				$('.c-footer__logo').attr('src', '/images/logo-footer-blue.png');
				// Replace contacts text
				$('.js-contacts-replace').html('ПО РЕМОНТУ CHEVROLET');
				// Choose engine
				$('#js-engine-type-gasoline').removeClass('c-services__engine-item--active');
				$('#js-engine-type-diesel').addClass('c-services__engine-item--active');
				$('#js-pricing-gasoline').addClass('u-hidden');
				$('#js-pricing-diesel').removeClass('u-hidden');
				// Replace modal text
				$('.js-name-of-auto').html('CHEVROLET ');
				break;
			case 'skoda':
				// Set theme blue
				$('#themeCSS').attr('href', '/styles/theme-blue.css');
				// Replace logo
				$('.c-nav__logo').attr('src', '/images/logo-blue.png');
				// Set header text
				$('.c-nav__logo-text').append(' SKODA');
				// Replace banner
				$('.c-hero').attr('style', 'background-image: url(\'../uploads/banner-skoda.jpg\')');
				// Set hero title
				$('.c-hero__title').html('АВТОСЕРВИС <span>SKODA</span> НА ВАРШАВКЕ <span>33</span>');
				// Set hero description
				$('#js-hero-description').append(' SKODA');
				// Replace man
				$('.c-diagnostics__man').attr('src', '/images/man-blue.png');
				// Set engine description
				$('.c-services__description').append(' SKODA');
				// Replace man 2
				$('.c-contacts-info__man').attr('src', '/images/man-2-blue.png');
				// Replace footer logo
				$('.c-footer__logo').attr('src', '/images/logo-footer-blue.png');
				// Replace contacts text
				$('.js-contacts-replace').html('ПО РЕМОНТУ SKODA');
				// Choose engine
				$('#js-engine-type-gasoline').addClass('c-services__engine-item--active');
				$('#js-engine-type-diesel').removeClass('c-services__engine-item--active');
				$('#js-pricing-gasoline').removeClass('u-hidden');
				$('#js-pricing-diesel').addClass('u-hidden');
				// Replace modal text
				$('.js-name-of-auto').html('SKODA ');
				break;
			case 'skoda_diesel':
				// Set theme blue
				$('#themeCSS').attr('href', '/styles/theme-blue.css');
				// Replace logo
				$('.c-nav__logo').attr('src', '/images/logo-blue.png');
				// Set header text
				$('.c-nav__logo-text').append(' SKODA');
				// Replace banner
				$('.c-hero').attr('style', 'background-image: url(\'../uploads/banner-skoda.jpg\')');
				// Set hero title
				$('.c-hero__title').html('АВТОСЕРВИС <span>SKODA</span> НА ВАРШАВКЕ <span>33</span>');
				// Set hero description
				$('#js-hero-description').append(' SKODA');
				// Replace man
				$('.c-diagnostics__man').attr('src', '/images/man-blue.png');
				// Set engine description
				$('.c-services__description').append(' SKODA');
				// Replace man 2
				$('.c-contacts-info__man').attr('src', '/images/man-2-blue.png');
				// Replace footer logo
				$('.c-footer__logo').attr('src', '/images/logo-footer-blue.png');
				// Replace contacts text
				$('.js-contacts-replace').html('ПО РЕМОНТУ SKODA');
				// Choose engine
				$('#js-engine-type-gasoline').removeClass('c-services__engine-item--active');
				$('#js-engine-type-diesel').addClass('c-services__engine-item--active');
				$('#js-pricing-gasoline').addClass('u-hidden');
				$('#js-pricing-diesel').removeClass('u-hidden');
				// Replace modal text
				$('.js-name-of-auto').html('SKODA ');
				break;
			case 'nissan':
				// Set theme blue
				$('#themeCSS').attr('href', '/styles/theme-yellow.css');
				// Replace logo
				$('.c-nav__logo').attr('src', '/images/logo-yellow.png');
				// Set header text
				$('.c-nav__logo-text').append(' NISSAN');
				// Replace banner
				$('.c-hero').attr('style', 'background-image: url(\'../uploads/banner-nissan.jpg\')');
				// Set hero title
				$('.c-hero__title').html('АВТОСЕРВИС <span>NISSAN</span> НА ВАРШАВКЕ <span>33</span>');
				// Set hero description
				$('#js-hero-description').append(' NISSAN');
				// Replace man
				$('.c-diagnostics__man').attr('src', '/images/man-yellow.png');
				// Set engine description
				$('.c-services__description').append(' NISSAN');
				// Replace man 2
				$('.c-contacts-info__man').attr('src', '/images/man-2-yellow.png');
				// Replace footer logo
				$('.c-footer__logo').attr('src', '/images/logo-footer-yellow.png');
				// Replace contacts text
				$('.js-contacts-replace').html('ПО РЕМОНТУ NISSAN');
				// Choose engine
				$('#js-engine-type-gasoline').addClass('c-services__engine-item--active');
				$('#js-engine-type-diesel').removeClass('c-services__engine-item--active');
				$('#js-pricing-gasoline').removeClass('u-hidden');
				$('#js-pricing-diesel').addClass('u-hidden');
				// Replace modal text
				$('.js-name-of-auto').html('NISSAN ');
				break;
			case 'nissan_diesel':
				// Set theme blue
				$('#themeCSS').attr('href', '/styles/theme-yellow.css');
				// Replace logo
				$('.c-nav__logo').attr('src', '/images/logo-yellow.png');
				// Set header text
				$('.c-nav__logo-text').append(' NISSAN');
				// Replace banner
				$('.c-hero').attr('style', 'background-image: url(\'../uploads/banner-nissan.jpg\')');
				// Set hero title
				$('.c-hero__title').html('АВТОСЕРВИС <span>NISSAN</span> НА ВАРШАВКЕ <span>33</span>');
				// Set hero description
				$('#js-hero-description').append(' NISSAN');
				// Replace man
				$('.c-diagnostics__man').attr('src', '/images/man-yellow.png');
				// Set engine description
				$('.c-services__description').append(' NISSAN');
				// Replace man 2
				$('.c-contacts-info__man').attr('src', '/images/man-2-yellow.png');
				// Replace footer logo
				$('.c-footer__logo').attr('src', '/images/logo-footer-yellow.png');
				// Replace contacts text
				$('.js-contacts-replace').html('ПО РЕМОНТУ NISSAN');
				// Choose engine
				$('#js-engine-type-gasoline').removeClass('c-services__engine-item--active');
				$('#js-engine-type-diesel').addClass('c-services__engine-item--active');
				$('#js-pricing-gasoline').addClass('u-hidden');
				$('#js-pricing-diesel').removeClass('u-hidden');
				// Replace modal text
				$('.js-name-of-auto').html('NISSAN ');
				break;
			case 'opel':
				// Set theme blue
				$('#themeCSS').attr('href', '/styles/theme-yellow.css');
				// Replace logo
				$('.c-nav__logo').attr('src', '/images/logo-yellow.png');
				// Set header text
				$('.c-nav__logo-text').append(' OPEL');
				// Replace banner
				$('.c-hero').attr('style', 'background-image: url(\'../uploads/banner-opel.jpg\')');
				// Set hero title
				$('.c-hero__title').html('АВТОСЕРВИС <span>OPEL</span> НА ВАРШАВКЕ <span>33</span>');
				// Set hero description
				$('#js-hero-description').append(' OPEL');
				// Replace man
				$('.c-diagnostics__man').attr('src', '/images/man-yellow.png');
				// Set engine description
				$('.c-services__description').append(' OPEL');
				// Replace man 2
				$('.c-contacts-info__man').attr('src', '/images/man-2-yellow.png');
				// Replace footer logo
				$('.c-footer__logo').attr('src', '/images/logo-footer-yellow.png');
				// Replace contacts text
				$('.js-contacts-replace').html('ПО РЕМОНТУ OPEL');
				// Choose engine
				$('#js-engine-type-gasoline').addClass('c-services__engine-item--active');
				$('#js-engine-type-diesel').removeClass('c-services__engine-item--active');
				$('#js-pricing-gasoline').removeClass('u-hidden');
				$('#js-pricing-diesel').addClass('u-hidden');
				// Replace modal text
				$('.js-name-of-auto').html('OPEL ');
				break;
			case 'opel_diesel':
				// Set theme blue
				$('#themeCSS').attr('href', '/styles/theme-yellow.css');
				// Replace logo
				$('.c-nav__logo').attr('src', '/images/logo-yellow.png');
				// Set header text
				$('.c-nav__logo-text').append(' OPEL');
				// Replace banner
				$('.c-hero').attr('style', 'background-image: url(\'../uploads/banner-opel.jpg\')');
				// Set hero title
				$('.c-hero__title').html('АВТОСЕРВИС <span>OPEL</span> НА ВАРШАВКЕ <span>33</span>');
				// Set hero description
				$('#js-hero-description').append(' OPEL');
				// Replace man
				$('.c-diagnostics__man').attr('src', '/images/man-yellow.png');
				// Set engine description
				$('.c-services__description').append(' OPEL');
				// Replace man 2
				$('.c-contacts-info__man').attr('src', '/images/man-2-yellow.png');
				// Replace footer logo
				$('.c-footer__logo').attr('src', '/images/logo-footer-yellow.png');
				// Replace contacts text
				$('.js-contacts-replace').html('ПО РЕМОНТУ OPEL');
				// Choose engine
				$('#js-engine-type-gasoline').removeClass('c-services__engine-item--active');
				$('#js-engine-type-diesel').addClass('c-services__engine-item--active');
				$('#js-pricing-gasoline').addClass('u-hidden');
				$('#js-pricing-diesel').removeClass('u-hidden');
				// Replace modal text
				$('.js-name-of-auto').html('OPEL ');
				break;
			case 'peugeot':
				// Set theme blue
				$('#themeCSS').attr('href', '/styles/theme-yellow.css');
				// Replace logo
				$('.c-nav__logo').attr('src', '/images/logo-yellow.png');
				// Set header text
				$('.c-nav__logo-text').append(' PEUGEOT');
				// Replace banner
				$('.c-hero').attr('style', 'background-image: url(\'../uploads/banner-peugeot.jpg\')');
				// Set hero title
				$('.c-hero__title').html('АВТОСЕРВИС <span>PEUGEOT</span> НА ВАРШАВКЕ <span>33</span>');
				// Set hero description
				$('#js-hero-description').append(' PEUGEOT');
				// Replace man
				$('.c-diagnostics__man').attr('src', '/images/man-yellow.png');
				// Set engine description
				$('.c-services__description').append(' PEUGEOT');
				// Replace man 2
				$('.c-contacts-info__man').attr('src', '/images/man-2-yellow.png');
				// Replace footer logo
				$('.c-footer__logo').attr('src', '/images/logo-footer-yellow.png');
				// Replace contacts text
				$('.js-contacts-replace').html('ПО РЕМОНТУ PEUGEOT');
				// Choose engine
				$('#js-engine-type-gasoline').addClass('c-services__engine-item--active');
				$('#js-engine-type-diesel').removeClass('c-services__engine-item--active');
				$('#js-pricing-gasoline').removeClass('u-hidden');
				$('#js-pricing-diesel').addClass('u-hidden');
				// Replace modal text
				$('.js-name-of-auto').html('PEUGEOT ');
				break;
			case 'peugeot_diesel':
				// Set theme blue
				$('#themeCSS').attr('href', '/styles/theme-yellow.css');
				// Replace logo
				$('.c-nav__logo').attr('src', '/images/logo-yellow.png');
				// Set header text
				$('.c-nav__logo-text').append(' PEUGEOT');
				// Replace banner
				$('.c-hero').attr('style', 'background-image: url(\'../uploads/banner-peugeot.jpg\')');
				// Set hero title
				$('.c-hero__title').html('АВТОСЕРВИС <span>PEUGEOT</span> НА ВАРШАВКЕ <span>33</span>');
				// Set hero description
				$('#js-hero-description').append(' PEUGEOT');
				// Replace man
				$('.c-diagnostics__man').attr('src', '/images/man-yellow.png');
				// Set engine description
				$('.c-services__description').append(' PEUGEOT');
				// Replace man 2
				$('.c-contacts-info__man').attr('src', '/images/man-2-yellow.png');
				// Replace footer logo
				$('.c-footer__logo').attr('src', '/images/logo-footer-yellow.png');
				// Replace contacts text
				$('.js-contacts-replace').html('ПО РЕМОНТУ PEUGEOT');
				// Choose engine
				$('#js-engine-type-gasoline').removeClass('c-services__engine-item--active');
				$('#js-engine-type-diesel').addClass('c-services__engine-item--active');
				$('#js-pricing-gasoline').addClass('u-hidden');
				$('#js-pricing-diesel').removeClass('u-hidden');
				// Replace modal text
				$('.js-name-of-auto').html('PEUGEOT ');
				break;
			case 'renault':
				// Set theme blue
				$('#themeCSS').attr('href', '/styles/theme-yellow.css');
				// Replace logo
				$('.c-nav__logo').attr('src', '/images/logo-yellow.png');
				// Set header text
				$('.c-nav__logo-text').append(' RENAULT');
				// Replace banner
				$('.c-hero').attr('style', 'background-image: url(\'../uploads/banner-renault.jpg\')');
				// Set hero title
				$('.c-hero__title').html('АВТОСЕРВИС <span>RENAULT</span> НА ВАРШАВКЕ <span>33</span>');
				// Set hero description
				$('#js-hero-description').append(' RENAULT');
				// Replace man
				$('.c-diagnostics__man').attr('src', '/images/man-yellow.png');
				// Set engine description
				$('.c-services__description').append(' RENAULT');
				// Replace man 2
				$('.c-contacts-info__man').attr('src', '/images/man-2-yellow.png');
				// Replace footer logo
				$('.c-footer__logo').attr('src', '/images/logo-footer-yellow.png');
				// Replace contacts text
				$('.js-contacts-replace').html('ПО РЕМОНТУ RENAULT');
				// Choose engine
				$('#js-engine-type-gasoline').addClass('c-services__engine-item--active');
				$('#js-engine-type-diesel').removeClass('c-services__engine-item--active');
				$('#js-pricing-gasoline').removeClass('u-hidden');
				$('#js-pricing-diesel').addClass('u-hidden');
				// Replace modal text
				$('.js-name-of-auto').html('RENAULT ');
				break;
			case 'renault_diesel':
				// Set theme blue
				$('#themeCSS').attr('href', '/styles/theme-yellow.css');
				// Replace logo
				$('.c-nav__logo').attr('src', '/images/logo-yellow.png');
				// Set header text
				$('.c-nav__logo-text').append(' RENAULT');
				// Replace banner
				$('.c-hero').attr('style', 'background-image: url(\'../uploads/banner-renault.jpg\')');
				// Set hero title
				$('.c-hero__title').html('АВТОСЕРВИС <span>RENAULT</span> НА ВАРШАВКЕ <span>33</span>');
				// Set hero description
				$('#js-hero-description').append(' RENAULT');
				// Replace man
				$('.c-diagnostics__man').attr('src', '/images/man-yellow.png');
				// Set engine description
				$('.c-services__description').append(' RENAULT');
				// Replace man 2
				$('.c-contacts-info__man').attr('src', '/images/man-2-yellow.png');
				// Replace footer logo
				$('.c-footer__logo').attr('src', '/images/logo-footer-yellow.png');
				// Replace contacts text
				$('.js-contacts-replace').html('ПО РЕМОНТУ RENAULT');
				// Choose engine
				$('#js-engine-type-gasoline').removeClass('c-services__engine-item--active');
				$('#js-engine-type-diesel').addClass('c-services__engine-item--active');
				$('#js-pricing-gasoline').addClass('u-hidden');
				$('#js-pricing-diesel').removeClass('u-hidden');
				// Replace modal text
				$('.js-name-of-auto').html('RENAULT ');
				break;
			default:
				console.log('Wrong value for "utm_content"!');
			}
		}
	}
};

$(function() {
	app.ui.disableDragging();
	app.ui.changeViewport();
	app.ui.headerSticky();
	app.ui.initGallery();
	app.ui.initReviews();
	app.ui.validatePhone();
	app.ui.chooseEngyneType();
	app.ui.addReview();
	app.ui.validateForms();
	app.ui.utmChangeContent();
	app.ui.scrollToAnchor();
	app.ui.showPopup();
	// Forms
	app.ui.submitForm();
	app.ui.submitFormModal();
	app.ui.submitFormModalMap();
	app.ui.submitReview();
});

$(window).on('resize', function() {});
